import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { RxArrowTopRight } from "react-icons/rx";
import { BsGithub } from "react-icons/bs";
import { Container } from "react-bootstrap";

function getCodeLink(props) {
  if (props.hasCode) {
    return <Button variant="primary" href={props.ghLink} target="_blank">
      {"Code"}&nbsp;
      <RxArrowTopRight />
    </Button>
  }
  return
}

function getPaperLink(props) {
  const margin = props.hasCode ? "10px" : "0px"
  const demo = <Button
    variant="primary"
    href={props.paperLink}
    target="_blank"
    style={{ marginLeft: margin }}
  >
    {"Paper"}&nbsp;
    <RxArrowTopRight />
  </Button>
  if (props.hasPaper) {
    return demo
  }
  return
}

function getDemoLink(props) {
  const margin = (props.hasCode || props.hasPaper) ? "10px" : "0px"
  const demo = <Button
    variant="primary"
    href={props.demoLink}
    target="_blank"
    style={{ marginLeft: margin }}
  >
    {"Demo"}&nbsp;
    <RxArrowTopRight />
  </Button>
  if (props.hasDemo) {
    return demo
  }
  return
}

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body className="d-flex flex-column">
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "left" }}>
          {props.description}
        </Card.Text>
        <div variant="d-flex flex-row">
          {getCodeLink(props)}
          {getPaperLink(props)}
          {getDemoLink(props)}
        </div>
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
