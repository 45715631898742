import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Typewriter } from 'react-simple-typewriter';
import Projects from "../Projects/Projects";
import Footer from "../../components/Footer";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                hi, I'm <strong className="main-name">Angela.</strong>{" "}
                I'm from <Typewriter words={["Shanghai.", "Toronto.", "Chicago."]}
                  loop={0} deleteSpeed={100} typeSpeed={70} /><br /><br />
                Currently a machine learning engineer at Apple Knowledge working on entity linking, QA, and search. <br />
                Previously studying computer science & statistics at <a>UChicago</a>;
                researching adversarial machine learning at <a href="http://sandlab.cs.uchicago.edu">SAND Lab</a>.<br /><br />
              </h1>
              <h3 className="homelinks">
                <a href="">Research (ML robustness)</a><br />
                <a href="">Work</a><br />
                <a href="">About me</a><br />
                <a href="https://www.linkedin.com/in/angela-yuxin-sha-9ab44a161/">Connect</a>
              </h3>



            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              {/* <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              /> */}
            </Col>
          </Row>
        </Container>
      </Container>
      <Projects />
      <Footer />
    </section >
  );
}

export default Home;
